import React, { useState } from 'react';
import {
  DialogContent,
  DialogContentText,
  Link,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import { StyledDialog } from '../components';
import { PropertyPolicy } from '../../state';

const useStyles = makeStyles(
  createStyles({
    button: {
      verticalAlign: 'unset',
    },
  })
);

interface Props {
  policy?: PropertyPolicy;
}

export const RulesAndRestrictionsModal: React.FC<Props> = ({ policy }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Link
        component="button"
        classes={styles}
        type="button"
        variant="body2"
        onClick={() => setOpen(true)}
      >
        Rules and Restrictions
      </Link>
      <StyledDialog title="Rules and Restrictions" open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogContentText variant="h5">
            MAKING, MODIFYING AND CANCELLING RESERVATIONS
          </DialogContentText>
          <DialogContentText>
            Reservations may be made directly with our hotels through our company website
            (www.armadillohotel.com) or through an online booking service (expedia.com, booking.com
            etc.). {policy?.flex}
          </DialogContentText>
          <DialogContentText variant="h5">CHECK-IN/CHECK-OUT</DialogContentText>
          <DialogContentText>{policy?.checkIn}</DialogContentText>
          <DialogContentText>{policy?.checkOut}</DialogContentText>
          <DialogContentText variant="h5">OTHER TERMS AND CONDITIONS</DialogContentText>
          <DialogContentText>
            Guest agrees that any claim for bodily injury sustained on property shall first be
            submitted to his/her employer’s workers compensation insurance provider. Guest and
            guest’s employer hereby notify Armadillo, pursuant to Texas Administrative Code Section
            3.161(b)(6)(A)(i), of the intent to use or occupy a room or space within each of
            Armadillo Hotel’s Texas locations for the next 30 or more consecutive days. Occupancy
            tax will be due from the first day of the stay for any rooms not occupied for 30 or more
            consecutive days.
          </DialogContentText>
          <DialogContentText>
            Armadillo has developed proprietary systems to seek to minimize any applicable occupancy
            taxes related to the services provided hereto and shall retain as additional
            compensation any such tax exemptions prepared by Armadillo and claimed for guest or
            guest’s employer. In addition, Armadillo shall retain as additional compensation all of
            the tax exemptions for any past due account aged 60 days or more.
          </DialogContentText>
          <DialogContentText>
            GUEST AND GUEST’S EMPLOYER AGREE TO DEFEND, RELEASE, INDEMNIFY AND HOLD HARMLESS
            ARMADILLO AND THE AFFILIATES, OWNERS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND
            REPRESENTATIVES OF ANY OF THE FOREGOING (THE “INDEMNIFIED PARTIES”) FROM AND AGAINST ANY
            AND ALL LOSSES, DAMAGES, EXPENSES OR LIABILITIES OF ANY KIND INCLUDING REASONABLE
            ATTORNEYS’ FEES (“LIABILITIES”) CAUSED BY, ARISING FROM OR OUT OF, ATTRIBUTABLE TO OR IN
            CONNECTION WITH (A) ACTS OR OMISSIONS OF GUEST AND GUEST’S EMPLOYER WHICH CONSTITUTE
            NEGLIGENCE, GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, (B) THE BREACH BY GUEST OR GUEST’S
            EMPLOYER OF ANY OF ITS OBLIGATIONS CONTAINED IN THESE TERMS AND CONDITIONS, (C) THE
            BODILY INJURY OR DEATH OF GUEST ANY PERSONNEL OF GUEST’S EMPLOYER, AND (D) ANY OCCUPANCY
            OR SALES TAXES (OR INTEREST AND PENALTIES RELATED TO DISALLOWANCE OF ANY CLAIMED
            EXEMPTIONS) RELATED TO THE SERVICES PROVIDED HERETO. IT IS THE INTENTION OF THE PARTIES
            THAT THE FOREGOING INDEMNIFICATION OBLIGATIONS APPLY TO ALL LIABILITIES OF EVERY KIND
            AND NATURE, WHETHER ARISING UNDER THE LAW OF CONTRACTS, PROPERTY OR TORTS, OR BY
            STATUTE, AT COMMON LAW OR IN EQUITY AND REGARDLESS OF THE CONTRIBUTORY NEGLIGENCE OF
            ARMADILLO.
          </DialogContentText>
          <DialogContentText>
            THESE TERMS AND CONDITIONS SHALL BE CONSTRUED IN ACCORDANCE WITH AND GOVERNED BY THE
            LAWS OF THE STATE OF TEXAS, WITHOUT REFERENCE TO THE CONFLICT OF LAWS PRINCIPLES
            THEREOF. IN THE EVENT OF ANY DISPUTE RELATED TO THE SERVICES PROVIDED BY ARMADILLO, THE
            PARTIES AGREE TO SUBMIT TO THE EXCLUSIVE JURISDICTION OF THE FEDERAL AND STATE COURTS
            SITTING IN MIDLAND COUNTY AND HEREBY WAIVE ANY RIGHT TO TRIAL BY JURY. ARMADILLO MAY, IN
            ITS SOLE AND ABSOLUTE DISCRETION, ELECT TO REFER ANY CONTROVERSY OR CLAIM TO BINDING
            ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION IN FRONT OF A SINGLE
            ARBITRATOR LOCATED IN AUSTIN, TX. THE PARTIES AGREE THAT ANY PRIOR FILED COURT ACTIONS
            SHALL BE DISMISSED AND JUDGEMENT ON THE AWARD RENDERED BY THE ARBITRATOR MAY BE ENTERED
            IN ANY COURT HAVING JURISDICTION THEREOF.
          </DialogContentText>
          <DialogContentText>
            In no event shall Armadillo be liable to customer or customer’s employer for any
            special, indirect or consequential damages, or damages for loss of opportunity, loss of
            profit, increased operating cost or costs associated with any business interruption, or
            any other similar damages of any nature or kind.
          </DialogContentText>

          <DialogContentText variant="h5">HOTEL POLICIES</DialogContentText>
          <DialogContentText>
            By booking a room at one of our hotels, guest hereby agrees to the following Hotel
            Policies:
          </DialogContentText>
          <ul>
            <li>
              <DialogContentText>
                Maximum occupancy is limited to no more than two persons per room. At least one
                registered adult guest (18+) is required per room.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>All overnight guests must be registered.</DialogContentText>
            </li>
            <li>
              <DialogContentText>
                No pets allowed (other than legitimate service animals).
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                No firearms or other weapons are allowed on premises for any reason, regardless of
                whether guest possesses concealed or open carry license.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                No illegal drugs are permitted on premises for any reason.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                No alcoholic beverages are permitted on premises unless served by the hotel and
                consumed in designated areas.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Each room is furnished with lockable storage drawers/cabinets. The hotel is not
                responsible for any loss of personal items left in rooms.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                The hotel is not responsible for property damage or personal injuries (including,
                without limitation, accidental death) due to vehicular accidents caused by guests.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                The hotel is not responsible for any personal injuries (including, without
                limitation, accidental death) incurred while using the gym/fitness center
                facilities, where available. If you use the gym/fitness center facilities, you do so
                at your own risk.
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                The hotel utilizes video surveillance cameras throughout the property including,
                without limitation, the parking areas and grounds, the administration building and
                the breezeways and corridors leading to the guest rooms. Surveillance video and
                images may be retained indefinitely.
              </DialogContentText>
            </li>
          </ul>
          <DialogContentText variant="h5">GUEST RESPONSIBILITIES</DialogContentText>
          <DialogContentText>
            The hotel management in its sole and absolute discretion may remove a guest immediately
            from the property without refund for any the following actions:
          </DialogContentText>
          <ul>
            <li>
              <DialogContentText>Violating any of the foregoing hotel policies</DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Abusive treatment of hotel staff or refusal to follow instructions of hotel staff or
                security personnel
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Being involved in any police matter unless the matter arises through no fault of
                your own or due to circumstances beyond your control
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Tampering with or removing window stops, smoke detectors or other safety measures
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Leaving room or common area windows or doors unlocked or open
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Soliciting or loitering of any kind, anywhere on the property
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>Parking in unauthorized areas</DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Parking utility trailers, oversized vehicles or inoperative vehicles on the property
                without authorization
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Washing, repairing, modifying, advertising the sale of or storing vehicles on the
                property
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Being outside your room without being fully clothed, including shirt and shoes
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Cooking, grilling or barbecuing in unauthorized areas or creating fire hazards
                on-premises
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Displaying items including signage, lighting, clothing, etc., outside your room
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Leaving minors unattended anywhere on property, including in your room
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Causing disruptions, including the use of car horns, in the parking lot
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Damaging or destroying hotel property and facilities anywhere on the property
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Disrupting other guests with loud noise, TV, music, disorderly conduct or large
                groups
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Feeding stray animals on the property or keeping animals inside parked vehicles
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Leaving trash or personal items in common spaces or unauthorized areas
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Using skates, skateboards, bikes, motorcycles, ATVs or other recreational vehicles
                or devices anywhere on the property
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Creating or staying in unsanitary conditions (trash, odors, dirty dishes, filth,
                etc.)
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Using the "Armadillo Hotel" name and/or contact information for any personal or
                commercial purpose including ongoing mail service, including social media,
                Craigslist or other internet posts
              </DialogContentText>
            </li>
            <li>
              <DialogContentText>
                Engaging in any activity, including social media, Craigslist, or other internet
                posts that might jeopardize the safety and privacy of any Armadillo guest or
                employee
              </DialogContentText>
            </li>
          </ul>

          <DialogContentText style={{ fontWeight: 2 }}>
            THE FOREGOING RULES, RESTRICTIONS, TERMS AND CONDITIONS AND HOTEL POLICIES AND GUEST
            RESPONSIBILITIES MAY BE MODIFIED FROM TIME TO TIME IN ARMADILLO’S SOLE AND ABSOLUTE
            DISCRETION WITHOUT PRIOR NOTICE TO GUEST
          </DialogContentText>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
