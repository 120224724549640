import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Grid,
  MenuItem,
  Typography,
  makeStyles,
  createStyles,
  FormControl,
  FormLabel,
  FormGroup,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startOfDay } from 'date-fns';

import {
  RoomType,
  FormStatus,
  PropertyAmenity,
  propertyQuery,
  sessionQuery,
  CorporateAccount,
  roomTypeQuery,
  roomTypeService,
} from '@lib/state';
import {
  FormTextField,
  FormCheckbox,
  SubmitButton,
  ErrorDisplay,
  FormNumberField,
  FormSelect,
  ConfirmedButton,
  Feedback,
  useObservable,
  usePropertyAmenities,
} from '@lib/common';
import {
  RoomRatePlan,
  AmenityFee,
  roomRatePlanQuery,
  roomRatePlanService,
  AdditionalProperty,
} from 'app/state';
import { RatePlanSelectList } from './rate-plan-selection-list.form';

const useStyles = makeStyles(theme =>
  createStyles({
    disableButton: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      minWidth: 200,
    },
    submitButton: {
      minWidth: 200,
    },
  })
);

export interface RatePlanFormFields {
  roomTypeId: RoomType['id'];
  name: string;
  priority: number;
  promoCode: string | null;
  standardRate: number;
  flexRate: number;
  housekeepingRate: number;
  housekeepingFee: number;
  earlyCheckInFee: number;
  lateCheckOutFee: number;
  dsoFee: number;
  startDate: Date;
  endDate: Date;
  weekdays: boolean;
  weekends: boolean;
  amenities: Array<{ name: string; code: string; available: boolean; fee: number }>;
  locations: Array<string>;
}

interface Props {
  plan?: RoomRatePlan;
  roomTypes: Array<RoomType>;
  corporateAccount?: CorporateAccount;

  status: FormStatus;
  error?: Error;

  onDisable?: () => void;
  onEnable?: () => void;
  resetFormUI: () => void;
  setSelectedRates: React.Dispatch<React.SetStateAction<AdditionalProperty[]>>;
  selectedRates: AdditionalProperty[];
}

export const RatePlanForm: React.FC<Props> = ({
  plan,
  corporateAccount,
  roomTypes,
  status,
  error,
  onDisable,
  onEnable,
  resetFormUI,
  setSelectedRates,
  selectedRates,
}) => {
  const styles = useStyles();

  const corporateRatePlans = useObservable(roomRatePlanQuery.corporateRatePlans, 'async');
  const propertyId = useObservable(sessionQuery.propertyId);
  const properties = useObservable(propertyQuery.properties);
  const allRoomTypes = useObservable(roomTypeQuery.roomTypes);
  const propertyAmenities = usePropertyAmenities(propertyId);

  const { errors, watch, setValue, register, formState } = useFormContext<RatePlanFormFields>();

  useEffect(() => {
    register('startDate', { required: true });
    register('endDate', { required: true });

    roomTypeService.getAllRoomTypes();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue('', plan);

    // eslint-disable-next-line
  }, [plan]);

  const { startDate, endDate, roomTypeId, amenities } = watch({ nest: true });

  const roomType = useMemo(() => roomTypes.find(rt => rt.id === roomTypeId), [
    roomTypes,
    roomTypeId,
  ]);

  const filteredRoomTypeIds = useMemo(() => {
    if (!!roomType && !!allRoomTypes?.length)
      return allRoomTypes
        .filter(x => x.name === roomType.name)
        .map(x => ({ roomTypeId: x.id, propertyId: x.propertyId! }));

    return undefined;
  }, [roomType, allRoomTypes]);

  const filteredRatePlans = useMemo(() => {
    if (!!filteredRoomTypeIds?.length && !!corporateRatePlans?.length) {
      const filteredPlans = corporateRatePlans.filter(
        x => x.id !== plan?.id && filteredRoomTypeIds.map(x => x.roomTypeId).includes(x.roomTypeId)
      );

      for (const property of properties) {
        if (
          property.id !== propertyId &&
          !filteredPlans.map(p => p.propertyId).includes(property.id)
        ) {
          filteredPlans.push({ propertyId: property.id } as RoomRatePlan);
        }
      }

      return filteredPlans;
    }
    return [];
  }, [filteredRoomTypeIds, corporateRatePlans, plan, properties, propertyId]);

  const additionalPropertiesList = useMemo(() => {
    if (!!filteredRatePlans && !!filteredRoomTypeIds?.length)
      return filteredRoomTypeIds.flatMap(x => {
        const propertyRatePlans = filteredRatePlans.filter(r => r.propertyId === x.propertyId);

        return propertyRatePlans.map(
          r =>
            ({
              ...r,
              propertyId: x.propertyId,
              roomTypeId: x.roomTypeId,
            } as RoomRatePlan)
        );
      });
    return undefined;
  }, [filteredRatePlans, filteredRoomTypeIds]);

  // todo: this should be replaced with a check on a roomtype attribute per #40668
  const isDouble = useMemo(() => roomType?.name.toLowerCase().includes('double'), [roomType]);

  useEffect(() => {
    if (!!corporateRatePlans && corporateRatePlans.length > 0) {
      roomTypeService.getRoomTypes(...corporateRatePlans.map(x => x.roomTypeId));
    }
  }, [corporateRatePlans]);

  useEffect(() => {
    if (!!corporateAccount?.id) {
      roomRatePlanService.getRatePlansByCorporateId(corporateAccount?.id);
    }
  }, [corporateAccount]);

  const { fields } = useFieldArray({
    name: 'amenities',
    keyName: 'code',
  });

  const [propertySelected, setPropertySelected] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <FormNumberField
          name="priority"
          label="Priority"
          defaultValue={10}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          integer
          fullWidth
          required
          data-testid="ratePlanPriorityInput"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormTextField
          name="name"
          label="Name"
          defaultValue={''}
          validationOptions={{ required: 'This field is required.' }}
          disabled={plan?.isDefault}
          fullWidth
          required
          data-testid="ratePlanNameInput"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormSelect
          name="roomTypeId"
          label="Room Type"
          defaultValue=""
          validationOptions={{ required: 'This field is required.' }}
          disabled={!!plan}
          fullWidth
          required
          data-testid="ratePlanRoomTypeSelect"
        >
          {roomTypes.map(x => (
            <MenuItem key={x.id} value={x.id}>
              {x.name}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormTextField
          name="promoCode"
          label="Promo Code"
          defaultValue={''}
          disabled={!!plan}
          fullWidth
          data-testid="ratePlanPromoCodeInput"
        />
      </Grid>
      {!plan?.isDefault && (
        <>
          <Grid item xs={12} md={3}>
            <DatePicker
              name="startDate"
              label="Start Date"
              format="MMMM d, yyyy"
              variant="inline"
              value={startDate ?? plan?.startDate}
              error={!!errors.startDate}
              onChange={value => value && setValue('startDate', startOfDay(value))}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              autoOk
              fullWidth
              data-testid="ratePlanStartDatePicker"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              name="endDate"
              label="End Date"
              format="MMMM d, yyyy"
              variant="inline"
              value={endDate ?? plan?.endDate}
              error={!!errors.endDate}
              onChange={value => value && setValue('endDate', startOfDay(value))}
              color="primary"
              InputProps={{ endAdornment: <FontAwesomeIcon icon="calendar-week" /> }}
              autoOk
              fullWidth
              data-testid="ratePlanEndDatePicker"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <FormControl>
          <FormLabel>Eligible Days</FormLabel>
          <FormGroup row>
            <FormCheckbox
              name="weekdays"
              label={<Typography variant="body2">Weekdays</Typography>}
              defaultChecked={plan?.weekdays}
              data-testid="ratePlanWeekdaysCheckbox"
            />
            <FormCheckbox
              name="weekends"
              label={<Typography variant="body2">Weekends</Typography>}
              defaultChecked={plan?.weekends}
              data-testid="ratePlanWeekendsCheckbox"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline">Rates</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="standardRate"
          label="Standard Rate"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          helperText={isDouble ? 'Per person assuming double occupancy.' : undefined}
          fullWidth
          required
          variant="filled"
          data-testid="ratePlanStandardRateInput"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="flexRate"
          label="Flex Rate"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          fullWidth
          required
          variant="filled"
          helperText="(additional)"
          data-testid="ratePlanFlexRateInput"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="housekeepingRate"
          label="Housekeeping Rate"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          fullWidth
          required
          variant="filled"
          helperText="(additional)"
          data-testid="ratePlanHouseKeepingInput"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="overline">Fees</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="housekeepingFee"
          label="Housekeeping Check Out Fee"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          fullWidth
          required
          variant="filled"
          helperText="Applies to all reservations."
          data-testid="ratePlanHouseKeepingFeeInput"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="earlyCheckInFee"
          label="Early Check In Fee"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          fullWidth
          required
          variant="filled"
          data-testid="ratePlanEarlyCheckInFeeInput"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <FormNumberField
          name="lateCheckOutFee"
          label="Late Check Out Fee"
          defaultValue={''}
          validationOptions={{
            required: 'This field is required.',
            min: {
              value: 0,
              message: 'Value cannot be less than zero',
            },
          }}
          fullWidth
          required
          variant="filled"
          data-testid="ratePlanLateCheckOutFeeInput"
        />
      </Grid>
      {isDouble && (
        <Grid item xs={12} md={3}>
          <FormNumberField
            name="dsoFee"
            label="Double Single Occupancy Fee"
            defaultValue={''}
            validationOptions={{
              required: 'This field is required.',
              min: {
                value: 0,
                message: 'Value cannot be less than zero',
              },
            }}
            fullWidth
            required
            variant="filled"
            data-testid="ratePlanInput"
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="overline">Value Add &amp; Amenities</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        {fields.map((field, index) => {
          const amenity = (amenities && amenities[index]) || { available: false };
          return (
            <FormControl key={field.code} fullWidth>
              <FormLabel>
                <FormCheckbox
                  name={`amenities.${index}.available`}
                  label={field.name}
                  defaultChecked={field.available}
                />
                <FormTextField
                  type="hidden"
                  name={`amenities.${index}.code`}
                  defaultValue={field.code}
                />
              </FormLabel>
              {amenity.available && (
                <FormNumberField
                  name={`amenities.${index}.fee`}
                  label="Rate"
                  defaultValue={field.fee}
                  validationOptions={{
                    required: 'This field is required.',
                    min: {
                      value: 0,
                      message: 'Value cannot be less than zero',
                    },
                  }}
                  fullWidth
                  required
                  variant="filled"
                  helperText={<>{amenity.fee === 0 ? '(included)' : '(additional)'}</>}
                />
              )}
            </FormControl>
          );
        })}
      </Grid>
      <Grid item xs={12} md={12}>
        {!!corporateAccount &&
          !!additionalPropertiesList?.length &&
          additionalPropertiesList.length > 0 && (
            <>
              <Grid item xs={12}>
                <Typography variant="overline">
                  Optionally, select additional properties to apply these changes to
                </Typography>
              </Grid>
              <RatePlanSelectList
                rates={additionalPropertiesList}
                roomTypes={allRoomTypes}
                properties={properties}
                propertyAmenities={propertyAmenities}
                setSelectedRates={setSelectedRates}
                selectedRates={selectedRates}
                setPropertySelected={setPropertySelected}
              />
            </>
          )}
      </Grid>

      <Grid item xs={12}>
        <Feedback show={status === FormStatus.Success} severity="success" onHide={resetFormUI}>
          Rate plan {plan ? 'saved' : 'created'} successfully!
        </Feedback>
        <ErrorDisplay error={error} />
        <Grid container spacing={2} justify="flex-end">
          {plan && !plan.isDefault && onDisable && !plan.disabledAt && (
            <Grid item>
              <ConfirmedButton
                type="button"
                className={styles.disableButton}
                variant="contained"
                action="Disable Rate Plan"
                onConfirm={onDisable}
                status={status}
                error={error}
                data-testid="ratePlanDisableButton"
              />
            </Grid>
          )}
          {plan && !plan.isDefault && onEnable && !!plan.disabledAt && (
            <Grid item>
              <ConfirmedButton
                type="button"
                color="secondary"
                variant="contained"
                action="Enable Rate Plan"
                onConfirm={onEnable}
                status={status}
                error={error}
                data-testid="ratePlanEnableButton"
              />
            </Grid>
          )}
          <Grid item>
            <SubmitButton
              classes={{ root: styles.submitButton }}
              variant="contained"
              color="secondary"
              pending={status === FormStatus.Pending}
              disabled={status === FormStatus.Pending || (!formState.dirty && !propertySelected)}
              data-testid="ratePlanSaveButton"
            >
              {plan ? 'Save' : 'Create'} Rate Plan
            </SubmitButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function prepareFormValues(
  propertyAmenities?: Array<PropertyAmenity>,
  plan?: Partial<RoomRatePlan>
) {
  const locations = new Array<string>();
  if (!propertyAmenities) return null; // return null if still loading amenities

  // build a map of plan amenities to merge into the options
  const amenityMap = new Map<string, AmenityFee>();
  if (plan?.amenities) {
    for (let amenity of plan.amenities) {
      amenityMap.set(amenity.code, amenity);
    }
  }

  const amenities = propertyAmenities.map(option => ({
    ...option,
    // option is available if associated with the plan
    available: amenityMap.has(option.code),
    // extend the option with data saved for the plan
    ...amenityMap.get(option.code),
  }));

  return { ...plan, amenities, locations };
}
