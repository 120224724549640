import { QueryEntity } from '@datorama/akita';
import { startOfDay, startOfToday } from 'date-fns';
import { of, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { PropertyStatus } from '../api/generated/properties';

import { SessionQuery, sessionQuery } from '../session';
import { getDate } from './property.model';
import { PropertyState, propertyStore, PropertyStore } from './property.store';
import { sortByName } from '../utils';

export class PropertyQuery extends QueryEntity<PropertyState> {
  constructor(protected store: PropertyStore, private readonly sessionQuery: SessionQuery) {
    super(store);
  }

  properties = this.selectAll({ sortBy: 'name' });

  openProperties = this.selectAll({
    filterBy: [x => x.status === PropertyStatus.Open],
    sortBy: 'name',
  });

  activeProperty = this.sessionQuery.propertyId.pipe(
    switchMap(id => (id != null ? this.selectEntity(id) : of(null)))
  );

  currentProvisioningCode = this.activeProperty.pipe(map(x => x?.provisioningCode));

  configuration = this.activeProperty.pipe(map(x => x?.configuration));

  amenities = this.activeProperty.pipe(map(x => x?.amenities));

  enrollmentToken = this.select(state => state.ui.enrollmentToken);

  currentStayDate = combineLatest([
    this.configuration,
    interval(1000 * 30).pipe(startWith(0)),
  ]).pipe(
    map(([property]) => (property ? startOfDay(getDate(property, 0, true)) : startOfToday())),
    distinctUntilChanged((x, y) => x.valueOf() === y.valueOf())
  );
}

export const propertyQuery = new PropertyQuery(propertyStore, sessionQuery);
