import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  useFormEvents,
  LoadingIndicator,
  ErrorDisplay,
  SubmitButton,
  FormTextField,
  Feedback,
  CurrencyDisplay,
  FormCheckbox,
  useObservable,
} from '@lib/common';
import {
  ReservationCharge,
  ReservationForms,
  FormStatus,
  Reservation,
  propertyQuery,
  RefundAccount,
} from '@lib/state';
import { Grid, Table, TableCell, TableFooter, TableRow, Typography } from '@material-ui/core';
import { JournalAccount } from 'app/state';
import {
  calculateTax,
  calculateSalesTax,
  parseLineItems,
  taxAccounts,
  salesTaxAccounts,
} from '../utils';
import { RefundLineItems } from './refund-line-items';

interface Props {
  reservation: Reservation;
  charge: ReservationCharge;
  accounts: JournalAccount[];
  isPartialRefund?: boolean;
  amountToRefund?: number;
}

const ReservationRefundFormComponent: React.FC<Props> = ({
  reservation,
  charge,
  accounts,
  isPartialRefund,
  amountToRefund,
}) => {
  const property = useObservable(propertyQuery.activeProperty);

  const { tax, salesTax } = useMemo(() => {
    const tax = reservation.rooms[0].rates[0].tax;
    const salesTax = reservation.rooms[0].rates[0].salesTax;

    return {
      tax,
      salesTax,
    };
  }, [reservation]);

  const useSalesTaxForMeals = useMemo(
    () => property?.configuration?.useSalesTaxForMealCosts ?? true,
    [property]
  );

  const [{ error, status }] = useFormEvents(ReservationForms.RefundReservationCharge);

  const { register, setValue, watch } = useFormContext();
  const { fields: refundFields, append: appendRefund } = useFieldArray({
    name: 'refundAccounts',
  });
  const { fields: taxFields, append: appendTax } = useFieldArray({
    name: 'taxAccounts',
  });

  useEffect(() => {
    register({ name: 'chargeId' }, { required: true });
    setValue('chargeId', charge.id);
  }, [charge, register, setValue]);

  useEffect(() => {
    const refunds: Array<RefundAccount> = [];

    for (let i = 0; i < 10; i++) {
      refunds.push({ account: '', amount: '' });
    }

    appendRefund(refunds);
  }, [appendRefund]);

  useEffect(() => {
    const taxes = calculateTax(0, tax);
    const salesTaxes = calculateSalesTax(0, salesTax);

    appendTax([...taxes, ...salesTaxes]);
  }, [appendTax, tax, salesTax]);

  const watched = watch(['refundAccounts', 'taxAccounts', 'calculateTax']);
  console.debug('WATCHED', watched);

  const {
    accounts: refundLineItems,
    occupancyTotal: occupancyRefundTotal,
    salesTotal: salesRefundTotal,
  } = useMemo(() => parseLineItems(watched.refundAccounts, useSalesTaxForMeals), [
    watched,
    useSalesTaxForMeals,
  ]);
  const {
    accounts: taxLineItems,
    occupancyTotal: occupancyTaxTotal,
    salesTotal: salesTaxTotal,
  } = useMemo(() => parseLineItems(watched.taxAccounts, useSalesTaxForMeals), [
    watched,
    useSalesTaxForMeals,
  ]);

  const calculateTaxEnabled = watched.calculateTax as boolean;

  useEffect(() => {
    if (calculateTaxEnabled) {
      // calculate tax accounts and amounts
      const taxes = calculateTax(occupancyRefundTotal, tax);
      const salesTaxes = calculateSalesTax(salesRefundTotal, salesTax);

      setValue('taxAccounts', [...taxes, ...salesTaxes]);
    } else {
      // deselect all tax accounts and clear amounts
      const taxes = taxAccounts.map(() => ({ account: null, amount: null }));
      const salesTaxes = salesTaxAccounts.map(() => ({ account: null, amount: null }));
      setValue('taxAccounts', [...taxes, ...salesTaxes]);
    }
  }, [
    setValue,
    charge,
    occupancyRefundTotal,
    salesRefundTotal,
    calculateTaxEnabled,
    tax,
    salesTax,
  ]);

  const validate = () => {
    if (occupancyRefundTotal + salesRefundTotal > charge.refundableAmount)
      return 'Refund total cannot be greater than the remaining refundable amount';
    if (refundLineItems.filter(x => !!x.amount).some(x => !x.valid))
      return 'Refund amounts must be valid currency amounts';
    if (refundLineItems.some(x => x.amount != null && x.amount < 0))
      return 'Refund amounts cannot be negative';
    if (refundLineItems.some(x => x.amount > 0 && !x.account))
      return 'Refund accounts must be specified for all entered amounts';

    const specifiedAccounts = [
      ...refundLineItems.filter(i => !!i.amount).map(i => i.account),
      ...taxLineItems.filter(i => !!i.amount).map(i => i.account),
    ];
    if (specifiedAccounts.length === 0 || occupancyRefundTotal + salesRefundTotal <= 0)
      return 'At least one refund account and amount must be specified';

    const distinctAccounts = new Set<string>(specifiedAccounts);
    if (specifiedAccounts.length > distinctAccounts.size)
      return 'Duplicate accounts must not be specified';

    return null;
  };

  const validationResult = validate();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormTextField
          type="text"
          name="description"
          label="Refund Description"
          fullWidth
          validationOptions={{ maxLength: 200 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Refund Accounts</Typography>

        <FormCheckbox name="calculateTax" label="Calculate Taxes Automatically" />

        <Feedback show={!!validationResult} severity="error">
          {validationResult}
        </Feedback>

        <Table size="small" padding="none">
          <RefundLineItems
            accounts={accounts}
            lineItems={refundFields}
            groupName="refundAccounts"
            showAccount={account =>
              !(
                calculateTaxEnabled &&
                (taxAccounts.some(t => t.account === account) ||
                  salesTaxAccounts.some(t => t.account === account))
              )
            }
            amountToRefund={amountToRefund}
            isPartialRefund={isPartialRefund}
          />
          {calculateTaxEnabled && (
            <RefundLineItems
              accounts={accounts}
              lineItems={taxFields}
              groupName="taxAccounts"
              readonly
              showAccount={account =>
                taxAccounts.some(t => t.account === account) ||
                salesTaxAccounts.some(t => t.account === account)
              }
            />
          )}
          <TableFooter>
            <TableRow>
              <TableCell>
                <Typography align="right">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography align="right">
                  <CurrencyDisplay
                    value={
                      occupancyRefundTotal +
                      salesRefundTotal +
                      (!isPartialRefund ? (occupancyTaxTotal ?? 0) + (salesTaxTotal ?? 0) : 0)
                    }
                  />
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay error={error} />
        {status === FormStatus.Pending ? <LoadingIndicator loadingText="Processing..." /> : null}
        <SubmitButton
          disabled={!!validationResult}
          style={{ display: 'block' }}
          variant="contained"
          color="secondary"
        >
          Refund
        </SubmitButton>
      </Grid>
    </Grid>
  );
};

export const ReservationRefundForm = ReservationRefundFormComponent;
