import React, { useCallback, useEffect, useState } from 'react';
import { DialogContent, Button, ButtonProps } from '@material-ui/core';
import { lightFormat } from 'date-fns';
import {
  Reservation,
  reservationService,
  Name,
  Address,
  roomRateQuery,
  roomRateService,
  ReservationForms,
  FormStatus,
  RoomRateForms,
  FormEvent,
  CheckReservationChangeBillingState,
  ReservationActivity,
  Accounting,
  OptionalAmenity,
  GuestProfile,
} from '@lib/state';
import {
  FormHub,
  getAcceptCardData,
  StyledDialog,
  useFormEvents,
  useHasRole,
  useLookup,
  useObservable,
  useChangeBillingPaymentMethods,
  useRoomRatesByReservation,
  LoadingIndicator,
} from '@lib/common';
import { ChangeBillingForm } from '.';

interface FormFields {
  billing: {
    name: Name;
    address: Address;
  };
  card?: {
    cardNumber: string;
    expiryDate: string;
    cvc: string;
  };
  savePaymentMethod?: boolean;
  paymentProfileId: string;
  amenities: Array<OptionalAmenity>;
  flexRate: boolean;
  dailyHousekeeping: boolean;
  corporateAccountId?: string;
  delegateUserId?: string;
}

interface Props extends Omit<ButtonProps, 'action' | 'onClick'> {
  reservation: Reservation;
  onSuccess?: () => void;
}

const ChangeBillingButtonComponent: React.FC<Props> = ({ reservation, onSuccess, ...props }) => {
  const [open, setOpen] = useState(false);
  const [{ status, error }, resetChangeBillingForm] = useFormEvents(ReservationForms.ChangeBilling);

  const closeDialog = useCallback(() => {
    roomRateService.resetUI();
    roomRateService.clearUpdateCost();
    resetChangeBillingForm();
    setOpen(false);
  }, [resetChangeBillingForm, setOpen]);

  useEffect(() => {
    if (status === FormStatus.Success) {
      closeDialog();
      if (onSuccess) onSuccess();
    }
  }, [status, closeDialog, onSuccess]);

  const allowed = CheckReservationChangeBillingState(reservation);

  if (!useHasRole(Accounting)) return null;

  if (!allowed) return null;

  return (
    <>
      <Button type="button" name="changeBilling" onClick={() => setOpen(true)} {...props}>
        Change Billing
      </Button>
      {open && (
        <StyledDialog title="Choose New Billing" open={open} onClose={() => closeDialog()}>
          <DialogContent>
            <ChangeBillingFormContainer reservation={reservation} status={status} error={error} />
          </DialogContent>
        </StyledDialog>
      )}
    </>
  );
};

const ChangeBillingFormContainer: React.FC<
  { reservation: Reservation } & Pick<FormEvent, 'status' | 'error'>
> = ({ reservation, status, error }) => {
  const [{ error: availabilityError, status: availabilityStatus }] = useFormEvents(
    RoomRateForms.Availability
  );

  // this may be the delegate choosen or the reservation's primary guest (when no delegate is selected)
  // it will be used to determine the rates that are available and also the payment methods
  const [bookingGuest, setBookingGuest] = useState<GuestProfile | undefined>(undefined);

  const [selectedCorporateAccountId, setSelectedCorporateAccountId] = useState<
    string | null | undefined
  >(reservation?.affiliation?.corporateAccountId ?? undefined);

  const { paymentMethods, guest } = useChangeBillingPaymentMethods(
    reservation,
    bookingGuest,
    selectedCorporateAccountId ?? undefined
  );
  const { roomRates, promoCode, loading } = useRoomRatesByReservation(
    reservation,
    ReservationActivity.ChangeBilling,
    bookingGuest?.userId,
    !!selectedCorporateAccountId ? selectedCorporateAccountId : undefined
  );

  const policy = useObservable(roomRateQuery.policy, 'async');
  const roomRateLookup = useLookup(roomRates, x => x.roomTypeId);

  const onChangeBookingGuest = useCallback(
    (guest?: GuestProfile, corporateAccountId?: string) => {
      setSelectedCorporateAccountId(corporateAccountId);
      setBookingGuest(guest);
    },
    [setBookingGuest]
  );

  const onSubmit = useCallback(
    ({
      billing,
      savePaymentMethod,
      paymentProfileId,
      card,
      amenities,
      flexRate,
      dailyHousekeeping,
      corporateAccountId,
      delegateUserId,
    }: FormFields) => {
      if (reservation) {
        reservationService.changeBilling(
          reservation,
          reservation.rooms.map(x => ({
            id: x.id,
            roomTypeId: x.roomTypeId,
            dailyRates:
              roomRateLookup.get(x.roomTypeId)?.dailyRates.map(y => ({
                ...y,
                date: lightFormat(y.date, 'yyyy-MM-dd'),
              })) ?? [],
          })),
          billing,
          !!savePaymentMethod,
          paymentProfileId,
          flexRate,
          dailyHousekeeping,
          getAcceptCardData(card),
          (amenities ?? [])
            .filter((x: OptionalAmenity) => x.selected)
            .map((x: OptionalAmenity) => x.code),
          promoCode,
          corporateAccountId,
          delegateUserId
        );
      }
    },
    [reservation, roomRateLookup, promoCode]
  );

  return (
    <FormHub onSubmit={onSubmit}>
      {!loading ? (
        <ChangeBillingForm
          reservation={reservation}
          paymentMethods={paymentMethods}
          status={status}
          error={error}
          availabilityStatus={availabilityStatus}
          availabilityError={availabilityError}
          paymentGuest={guest}
          policy={policy}
          roomRates={roomRates}
          onChangeBookingGuest={onChangeBookingGuest}
        />
      ) : (
        <LoadingIndicator />
      )}
    </FormHub>
  );
};

export const ChangeBillingButton = ChangeBillingButtonComponent;
