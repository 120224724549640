import {
  Properties,
  Reservations,
  ReservationPolicy,
  PointOfSale,
  AccessControl,
  Audit,
} from '@lib/state';

export type PropertyConfiguration = Properties.PropertyModel &
  Reservations.PropertyConfigurationFormModel &
  AccessControl.PropertyGroupResponse &
  Audit.PropertyRefundModel &
  Partial<PointOfSale.PropertyConfigurationModel>;

export type TimeThreshold = Reservations.TimeThreshold;

export interface UpdatePropertyConfigurationRequest {
  name?: string;
  propertyCode?: string;
  location?: Properties.Address;
  contact?: Properties.Contact;
  groupBooking?: Properties.Contact;
  taxRates?: Reservations.TaxRates;
  salesTaxRates?: PointOfSale.TaxRates;
  backOfficeEmail?: string | null;
  reportEmail?: string | null;
  maintenanceEmail?: string | null;
  maintenancePhone?: string | null;
  checkIn?: TimeThreshold;
  checkOut?: TimeThreshold;
  earlyCheckIn?: TimeThreshold;
  lateCheckOut?: TimeThreshold;
  changeableThreshold?: TimeThreshold;
  cancelableThreshold?: TimeThreshold;
  features?: string[];
  taxCreditPeriod?: number;
  useSalesTaxForMealCosts?: boolean;
}

export interface UpadatePropertyNotificationRequests {
  backOfficeEmail?: string | null;
  reportEmail?: string | null;
  maintenanceEmail?: string | null;
  maintenancePhone?: string | null;
  monitoringEmail?: string;
  refundAlertEmail?: string;
  taxCreditPeriod?: number;
  guestSurveyEmail?: string;
  guestSurveyThreshold?: number;
  vipReservationNotificationPhone?: string;
}

const minutesInADay = 1440;

// Convert the minutes to
export function formatOutgoingPolicy(
  request: UpdatePropertyConfigurationRequest
): UpdatePropertyConfigurationRequest;
export function formatOutgoingPolicy(request?: ReservationPolicy): ReservationPolicy | undefined;
export function formatOutgoingPolicy(
  request?: UpdatePropertyConfigurationRequest | ReservationPolicy
): UpdatePropertyConfigurationRequest | ReservationPolicy | undefined {
  if (!request) return request;

  const changeableThreshold = !!request.changeableThreshold
    ? negateGracePeriod(daysToMinutes(request.changeableThreshold))
    : undefined;

  const cancelableThreshold = !!request.cancelableThreshold
    ? negateGracePeriod(daysToMinutes(request.cancelableThreshold))
    : undefined;

  const checkIn = !!request.checkIn ? negateGracePeriod(request.checkIn) : undefined;

  const earlyCheckIn = !!request.earlyCheckIn ? negateGracePeriod(request.earlyCheckIn) : undefined;

  // one request had read-only properties, so had to create a new request object
  return { ...request, changeableThreshold, cancelableThreshold, checkIn, earlyCheckIn };
}

export function formatIncomingPolicy(request?: ReservationPolicy): ReservationPolicy | undefined {
  if (!request) return request;

  return {
    ...request,
    changeableThreshold: negateGracePeriod(minutesToDays(request.changeableThreshold)),
    cancelableThreshold: negateGracePeriod(minutesToDays(request.cancelableThreshold)),
    checkIn: negateGracePeriod(request.checkIn),
    earlyCheckIn: negateGracePeriod(request.earlyCheckIn),
  };
}

function daysToMinutes(timeThreshold: TimeThreshold): TimeThreshold {
  if (!timeThreshold?.gracePeriodInMinutes) return timeThreshold;

  return {
    ...timeThreshold,
    gracePeriodInMinutes: timeThreshold.gracePeriodInMinutes * minutesInADay,
  };
}

function minutesToDays(timeThreshold: TimeThreshold): TimeThreshold {
  if (!timeThreshold?.gracePeriodInMinutes) return timeThreshold;

  return {
    ...timeThreshold,
    gracePeriodInMinutes: timeThreshold.gracePeriodInMinutes / minutesInADay,
  };
}

function negateGracePeriod(timeThreshold: TimeThreshold): TimeThreshold {
  if (!timeThreshold?.gracePeriodInMinutes) return timeThreshold;

  return {
    ...timeThreshold,
    gracePeriodInMinutes: timeThreshold.gracePeriodInMinutes * -1,
  };
}
