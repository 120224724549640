import React, { useState, useEffect } from 'react';
import { DialogContent, DialogContentText, Button, Grid } from '@material-ui/core';

import { StyledDialog, CurrencyDisplay, useFormEvents, FormHub } from '@lib/common';
import {
  ReservationCharge,
  ReservationForms,
  FormStatus,
  RefundReservationChargeModel,
  Reservation,
  RefundForm,
} from '@lib/state';
import { ReservationRefundForm } from './reservation-refund.form';
import { JournalAccount } from 'app/state';

interface Props {
  reservation: Reservation;
  charge: ReservationCharge;
  accounts: JournalAccount[];
  onSubmit(model: RefundReservationChargeModel): void;
}

export const ReservationRefundModal: React.FC<Props> = ({
  reservation,
  charge,
  accounts,
  onSubmit,
}) => {
  const [{ status }] = useFormEvents(ReservationForms.RefundReservationCharge);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (status === FormStatus.Success) setOpen(false);
  }, [status]);

  return (
    <>
      <Button
        size="small"
        color="secondary"
        onClick={() => setOpen(true)}
        disabled={charge.refundableAmount <= 0}
      >
        {charge.refundableAmount <= 0 ? 'Refunded' : 'Refund'}
      </Button>
      <StyledDialog title="Refund" open={open} onClose={() => setOpen(false)} maxWidth={'md'}>
        <DialogContent>
          <DialogContentText>
            Please enter the amounts and accounts to be refunded.
          </DialogContentText>
          <Grid container>
            <Grid item xs={6}>
              <DialogContentText>
                Charge total: <CurrencyDisplay value={charge.totalAmount} />
              </DialogContentText>
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>
                Refundable amount: <CurrencyDisplay value={charge.refundableAmount} />
              </DialogContentText>
            </Grid>
          </Grid>

          <FormHub
            onSubmit={({ chargeId, description, refundAccounts, taxAccounts }: RefundForm) =>
              // map from form model to request model
              onSubmit({
                chargeId,
                description,
                refundAccounts: [...refundAccounts, ...(taxAccounts || [])]
                  .filter(({ amount }) => !!amount && parseFloat(amount) > 0)
                  .map(({ account, amount }) => ({ account, amount: parseFloat(amount || '0') })),
              })
            }
          >
            <ReservationRefundForm reservation={reservation} charge={charge} accounts={accounts} />
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
