import { TaxRates, TaxAmounts, RefundAccount } from '@lib/state';

//#region refund utils
export const taxAccounts: Array<{ key: keyof TaxRates; account: string }> = [
  { key: 'state', account: '2130' },
  { key: 'county', account: '2140' },
  { key: 'city', account: '2150' },
];

export const salesTaxAccounts: Array<{ key: string; account: string }> = [
  { key: 'sales', account: '2120' },
];

export const salesTaxedAccounts: Array<{ key: string; account: string }> = [
  { key: 'foodServiceRevenue', account: '4100' },
  { key: 'barRevenue', account: '4150' },
];

export function isSalesTaxable(account: string, useSalesTaxForMealPlan: boolean) {
  const isSalesTaxable = useSalesTaxForMealPlan
    ? true
    : account !== salesTaxedAccounts.find(s => s.key === 'foodServiceRevenue')?.account;

  return salesTaxedAccounts.some(s => s.account === account) && isSalesTaxable;
}

export function parseLineItems(
  lineItems: RefundAccount[] | undefined,
  useSalesTaxForMealPlan: boolean = true
) {
  const accounts =
    lineItems?.map(({ account, amount }) => {
      const parsedAmount = parseFloat(amount ?? '0');
      return { account, valid: !isNaN(parsedAmount), input: amount, amount: parsedAmount };
    }) ?? [];

  const occupancyTotal = accounts
    .filter(a => a.valid && !isSalesTaxable(a.account, useSalesTaxForMealPlan))
    .reduce((sum, account) => sum + (account.amount ?? 0), 0);
  const salesTotal = accounts
    .filter(a => a.valid && isSalesTaxable(a.account, useSalesTaxForMealPlan))
    .reduce((sum, account) => sum + (account.amount ?? 0), 0);

  return { accounts, occupancyTotal, salesTotal };
}

export function calculateTax(refundSubTotal: number, tax: TaxAmounts) {
  const rates = tax.rates ?? ({} as TaxRates);

  return taxAccounts
    .map(({ key, account }) => ({ rate: rates[key], account }))
    .map(
      ({ rate, account }) =>
        ({ account, amount: ((refundSubTotal * (rate ?? 0)) / 100).toFixed(2) } as RefundAccount)
    );
}

export function calculateSalesTax(refundSalesSubTotal: number, salesTax: TaxAmounts) {
  const salesRates = salesTax.rates ?? ({} as TaxRates);

  //Have to add all taxes here, as there is only one sales tax code.
  const salesTaxAmount = Object.keys(salesRates)
    .map(r => (refundSalesSubTotal * (salesRates[r as keyof TaxRates] ?? 0)) / 100)
    .reduce((sum, current) => sum + current, 0)
    .toFixed(2);

  return salesTaxAccounts.map(
    ({ key: _, account }) => ({ account, amount: salesTaxAmount } as RefundAccount)
  );
}
//#endregion
