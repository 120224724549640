import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Property, PropertyAmenity, RoomType } from '@lib/state';
import { useLookup, CurrencyDisplay, DateDisplay, LoadingIndicator, DataTable } from '@lib/common';
import { AdditionalProperty, mapRatePlanProperties, RoomRatePlan } from 'app/state';
import { RoomRateAmenitiesColumns } from './room-rate-amenities-columns';
import { GridSelectionModel } from '@mui/x-data-grid-pro';
import { DSOFeeColumn } from './dso-fee-column';

interface Props {
  rates: Array<RoomRatePlan>;
  roomTypes: Array<RoomType>;
  properties: Array<Property>;
  propertyAmenities?: Array<PropertyAmenity>;
  setSelectedRates: React.Dispatch<React.SetStateAction<AdditionalProperty[]>>;
  selectedRates: AdditionalProperty[];
  setPropertySelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RatePlanSelectList: React.FC<Props> = ({
  rates,
  roomTypes,
  properties,
  propertyAmenities,
  setSelectedRates,
  selectedRates,
  setPropertySelected,
}) => {
  const rateLookUp = useLookup(rates, x => x.id ?? x.propertyId);
  const roomTypeLookup = useLookup(roomTypes, x => x.id);
  const propertyLookup = useLookup(properties, x => x.id);
  const ratePlans = mapRatePlanProperties(rates, propertyLookup);
  const rateRoomTypes = rates.flatMap(r => roomTypes.filter(t => t.id === r.roomTypeId));

  useEffect(() => {}, [selectedRates]);

  if (!roomTypes.length || !propertyAmenities) return <LoadingIndicator />;

  return (
    <DataTable
      items={ratePlans}
      checkboxSelection={true}
      getItemKey={item => item.id ?? item.propertyId}
      onSelectionModelChange={(selectedRows: GridSelectionModel) => {
        const selectedRates = selectedRows.map(x => rateLookUp.get(x as string)!);
        setSelectedRates(selectedRates.map(x => x as AdditionalProperty));
        setPropertySelected(selectedRows.length > 0);
      }}
      selectionModel={selectedRates.map(x => (x.id ? x.id : x.propertyId))}
      columns={[
        {
          title: 'Property',
          valueFactory: plan => plan.propertyName,
        },
        {
          title: 'Room',
          valueFactory: plan => roomTypeLookup.get(plan.roomTypeId)?.name,
        },
        {
          title: 'Rank',
          valueFactory: plan =>
            !plan?.priority ? (
              <Typography>--</Typography>
            ) : (
              <Typography>{plan.priority}</Typography>
            ),
        },
        {
          title: 'Std Rate',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.standardRate),
          colSpan: 0.9,
        },
        {
          title: 'Flex Rate',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.flexRate),
          colSpan: 0.9,
        },
        {
          title: 'HK Rate',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.housekeepingRate),
          colSpan: 0.9,
        },
        {
          title: 'HK Fee',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.housekeepingFee),
          colSpan: 0.9,
        },
        {
          title: 'Early CI',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.earlyCheckInFee),
          colSpan: 0.9,
        },
        {
          title: 'Late CO',
          align: 'right',
          valueFactory: item => PlanCurrencyValueFactory(item.lateCheckOutFee),
          colSpan: 0.9,
        },
        {
          title: 'Start',
          valueFactory: item => <DateDisplay date={item?.startDate} dateFormat="M/d/yy" />,
          colSpan: 1.2,
        },
        {
          title: 'End',
          valueFactory: item => <DateDisplay date={item?.endDate} dateFormat="M/d/yy" />,
          colSpan: 1.2,
        },
        {
          title: 'Weekday Weekend',
          valueFactory: plan =>
            !plan.weekdays || !plan.weekends
              ? ''
              : '' + (plan.weekdays ? 'Yes' : 'No') + ' - ' + (plan.weekdays ? 'Yes' : 'No') + '',
          colSpan: 1.25,
        },
        ...RoomRateAmenitiesColumns(propertyAmenities),
        ...DSOFeeColumn(rateRoomTypes),
      ]}
    />
  );
};

function PlanCurrencyValueFactory(item?: number | null) {
  return !item ? <Typography>--</Typography> : <CurrencyDisplay value={item} />;
}
