import React from 'react';
import { Typography } from '@material-ui/core';

import { PropertyAmenity, RoomType } from '@lib/state';
import { useLookup, CurrencyDisplay, DateDisplay, NameDisplay, DataTable } from '@lib/common';
import { RoomRatePlan, Employee } from 'app/state';
import { RoomRateAmenitiesColumns } from './room-rate-amenities-columns';
import { DSOFeeColumn } from './dso-fee-column';

interface Props {
  history: Array<RoomRatePlan>;
  employees: Array<Employee>;
  propertyAmenities: Array<PropertyAmenity>;
  roomTypes: Array<RoomType>;
}

export const RatePlanHistory: React.FC<Props> = ({
  history,
  employees,
  propertyAmenities,
  roomTypes,
}) => {
  const employeeLookup = useLookup(employees, x => x.userId);
  const rateRoomTypes = history.flatMap(r => roomTypes.filter(t => t.id === r.roomTypeId));

  return (
    <DataTable
      items={history}
      getItemKey={item => item.id}
      columns={[
        {
          title: 'Name',
          valueFactory: item => item.name,
          colSpan: 1.5,
        },
        {
          title: 'Room Type',
          valueFactory: item => rateRoomTypes.find(r => r.id === item.roomTypeId)?.name,
        },
        {
          title: 'Rank',
          valueFactory: item => item.priority,
        },
        {
          title: 'Std Rate',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.standardRate} />,
          colSpan: 0.9,
        },
        {
          title: 'Flex Rate',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.flexRate} />,
          colSpan: 0.9,
        },
        {
          title: 'HK Rate',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.housekeepingRate} />,
          colSpan: 0.9,
        },
        {
          title: 'HK Fee',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.housekeepingFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Early CI',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.earlyCheckInFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Late CO',
          align: 'right',
          valueFactory: item => <CurrencyDisplay value={item.lateCheckOutFee} />,
          colSpan: 0.9,
        },
        {
          title: 'Start',
          valueFactory: item =>
            item.isDefault ? (
              <Typography>N/A</Typography>
            ) : (
              <DateDisplay date={item.startDate} dateFormat="M/d/yy" />
            ),
          colSpan: 1.2,
        },
        {
          title: 'End',
          valueFactory: item =>
            item.isDefault ? (
              <Typography>N/A</Typography>
            ) : (
              <DateDisplay date={item.endDate} dateFormat="M/d/yy" />
            ),
          colSpan: 1.2,
        },
        {
          title: 'Weekday Weekend',
          valueFactory: plan =>
            '' + (plan.weekdays ? 'Yes' : 'No') + ' - ' + (plan.weekdays ? 'Yes' : 'No') + '',
          colSpan: 1.25,
        },
        ...RoomRateAmenitiesColumns(propertyAmenities),
        ...DSOFeeColumn(rateRoomTypes),
        {
          title: 'Changed',
          valueFactory: item => <DateDisplay date={item.createdAt} dateFormat="M/d/yy" />,
        },
        {
          title: 'By',
          valueFactory: item => (
            <NameDisplay {...employeeLookup.get(item.createdById)?.name} directory />
          ),
        },
      ]}
    />
  );
};
