import {
  StyledDialog,
  useFormEvents,
  FormHub,
  ErrorDisplay,
  Feedback,
  useObservable,
} from '@lib/common';
import { FormStatus, CommonForm, sessionQuery } from '@lib/state';
import {
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { reportService } from 'app/state';
import { DatePicker } from '@material-ui/pickers';
import {
  isEqual,
  endOfMonth,
  startOfMonth,
  startOfToday,
  startOfYesterday,
  addMonths,
} from 'date-fns';

interface FormFields {
  startDate: string;
  endDate: string;
}

interface Props {
  propertyId: string;
}

export const TaxCreditExportButton: React.FC<Props> = ({ propertyId }) => {
  const [{ status, error }, resetUI] = useFormEvents(CommonForm.Export);
  const [showModal, setShowModal] = useState(false);
  const currentDate = new Date();
  const currentUser = useObservable(sessionQuery.currentUser);

  //if last day of the month, then set to start of current month
  //otherwise set to start of previous month
  const [startDate, setStartDate] = useState<Date | null>(
    isEqual(startOfToday(), endOfMonth(currentDate))
      ? startOfMonth(currentDate)
      : startOfMonth(addMonths(currentDate, -1))
  );

  //if last day of the month, then set to end of current month
  //otherwise set to end of previous month
  const [endDate, setEndDate] = useState<Date | null>(
    isEqual(startOfToday(), endOfMonth(currentDate))
      ? endOfMonth(currentDate)
      : endOfMonth(addMonths(currentDate, -1))
  );

  const [excludeRetroactiveTax, setExcludeRetroactiveTax] = useState(false);

  const onSubmit = useCallback(() => {
    if (startDate && endDate)
      reportService.exportTaxCreditReport(propertyId, startDate, endDate, excludeRetroactiveTax);
  }, [propertyId, startDate, endDate, excludeRetroactiveTax]);

  if (!currentUser) return null;

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          resetUI();
          setShowModal(true);
        }}
      >
        Export Tax Credit Report
      </Button>
      <StyledDialog
        title={'Export Tax Credit Report'}
        onClose={() => setShowModal(false)}
        open={showModal}
        maxWidth="lg"
        scroll="paper"
      >
        <DialogContent>
          <FormHub<FormFields> onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  autoOk
                  disableToolbar
                  name="startDate"
                  label="Start Date"
                  value={startDate}
                  fullWidth
                  required
                  onChange={value => setStartDate(value ? value : null)}
                  maxDate={startOfYesterday()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  autoOk
                  disableToolbar
                  name="endDate"
                  label="End Date"
                  value={endDate}
                  fullWidth
                  required
                  onChange={value => setEndDate(value ? value : null)}
                  maxDate={startOfYesterday()}
                  minDate={startDate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      aria-label="Customer Tax Credit Report (excludes retroactive tax credits)"
                      checked={excludeRetroactiveTax}
                      onChange={_ => setExcludeRetroactiveTax(!excludeRetroactiveTax)}
                    />
                  }
                  label="Customer Tax Credit Report (excludes retroactive tax credits)"
                />
              </Grid>
              <Grid item xs={12}>
                <Feedback show={status === FormStatus.Success} severity="success" onHide={resetUI}>
                  Tax Credit Report Requested
                </Feedback>
                <ErrorDisplay error={error} />
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={status === FormStatus.Pending}
                  name="generateTaxCreditReport"
                  onClick={onSubmit}
                >
                  Submit**
                </Button>
                <Typography variant="subtitle1">
                  **Report will be emailed to the Back Office Email upon completion.
                </Typography>
              </Grid>
            </Grid>
          </FormHub>
        </DialogContent>
      </StyledDialog>
    </>
  );
};
