import { FormSelect, FormCurrencyField } from '@lib/common';
import { TableBody, TableRow, TableCell, MenuItem } from '@material-ui/core';
import React from 'react';

export const RefundLineItems: React.FC<{
  groupName: string;
  amountToRefund?: number;
  isPartialRefund?: boolean;
  accounts: Array<{ accountNumber: string; label: string }>;
  showAccount(account: string): boolean;
  lineItems?: Array<{ id?: string } & Record<string, any>>;
  readonly?: boolean;
}> = ({
  accounts,
  lineItems,
  groupName,
  readonly = false,
  showAccount,
  amountToRefund,
  isPartialRefund,
}) => {
  console.debug('LINE ITEMS', groupName, lineItems);

  if (!lineItems) return null;

  if (isPartialRefund) {
    let lineItem: Array<{ id?: string } & Record<string, any>> | undefined;
    lineItem = [];
    if (lineItems[0] !== undefined) {
      lineItem.push(lineItems[0]);
    }
    return (
      <TableBody>
        {lineItem.map((item, idx) => (
          <TableRow key={item.id}>
            <TableCell>
              <FormSelect
                name={`${groupName}[${idx}].account`}
                label="Account"
                fullWidth
                disabled={readonly}
                defaultValue={item.account}
              >
                <MenuItem value={''}>None</MenuItem>
                {accounts.map(
                  ({ accountNumber: account, label }) =>
                    account &&
                    showAccount(account) && (
                      <MenuItem key={account} value={account}>
                        {label}
                      </MenuItem>
                    )
                )}
              </FormSelect>
            </TableCell>
            <TableCell align="right" width={140}>
              <FormCurrencyField
                name={`${groupName}[${idx}].amount`}
                label="Amount"
                fullWidth
                disabled={readonly || isPartialRefund}
                defaultValue={Number(amountToRefund).toFixed(2)}
                validationOptions={{
                  min: {
                    value: 0,
                    message: 'Value cannot be less than zero',
                  },
                }}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  }
  return (
    <TableBody>
      {lineItems.map((item, idx) => (
        <TableRow key={item.id}>
          <TableCell>
            <FormSelect
              name={`${groupName}[${idx}].account`}
              label="Account"
              fullWidth
              disabled={readonly}
              defaultValue={item.account}
            >
              <MenuItem value={''}>None</MenuItem>
              {accounts.map(
                ({ accountNumber: account, label }) =>
                  account &&
                  showAccount(account) && (
                    <MenuItem key={account} value={account}>
                      {label}
                    </MenuItem>
                  )
              )}
            </FormSelect>
          </TableCell>
          <TableCell align="right" width={140}>
            <FormCurrencyField
              name={`${groupName}[${idx}].amount`}
              label="Amount"
              fullWidth
              disabled={readonly}
              defaultValue={item.amount}
              validationOptions={{
                min: {
                  value: 0,
                  message: 'Value cannot be less than zero',
                },
              }}
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};
