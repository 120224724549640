import { Overwrite } from 'utility-types';
import { parseISO } from 'date-fns';

import { Property, Reservations } from '@lib/state';

interface DateProps {
  startDate: Date;
  endDate: Date;
  disabledAt: Date | null;
  createdAt: Date;
}

export type RatePlanSort = Reservations.RatePlanSort;
export const RatePlanSort = Reservations.RatePlanSort;

export type RoomRatePlan = Overwrite<Reservations.RoomRatePlanModel, DateProps>;
export type AmenityFee = Reservations.AmenityFeeModel;

export type AdditionalProperty = {
  id?: string | null;
  propertyId: string;
  roomTypeId: string;
};

export type RatePlanProperty = RoomRatePlan & {
  propertyName: string;
};

export function mapRoomRatePlan(ratePlan: Reservations.RoomRatePlanModel) {
  return {
    ...ratePlan,
    startDate: parseISO(ratePlan.startDate),
    endDate: parseISO(ratePlan.endDate),
    disabledAt: (ratePlan.disabledAt != null && parseISO(ratePlan.disabledAt)) || null,
    createdAt: parseISO(ratePlan.createdAt),
  };
}

const corporateRatePlanSort = (a: RatePlanProperty, b: RatePlanProperty) => {
  if (a.propertyId === b.propertyId) {
    return a.endDate.getTime() - b.endDate.getTime();
  }
  return a.propertyName.localeCompare(b.propertyName);
};

export function mapRatePlanProperties(
  ratePlans: Array<RoomRatePlan>,
  properties: Map<string, Property>
) {
  return ratePlans
    .map(
      r =>
        ({
          ...r,
          propertyName: properties.get(r?.propertyId)?.name,
        } as RatePlanProperty)
    )
    .sort(corporateRatePlanSort);
}
