import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import {
  RoomType,
  RoomRate,
  DailyRate,
  Property,
  FormStatus,
  roomRateQuery,
  roomRateService,
  PropertyPolicy,
  OptionalAmenity,
  Reservation,
  SleepSchedule,
} from '@lib/state';
import {
  CurrencyDisplay,
  DateDisplay,
  AddressDisplay,
  Section,
  SectionVariant,
  LoadingIndicator,
  formatCurrency,
  Analytics,
  HousekeepingPolicyDisplay,
} from '../../components';
import { FormCheckbox, FormTextField, ErrorDisplay } from '../../forms';
import { useLookup, useObservable } from '../../utils';
import { RulesAndRestrictionsModal } from '../rules-and-restrictions.modal';
import { useCommonStyle } from '../../commonStyle';
import { LocationFormFields } from './location-form';
import { ReservationStatus, RoomHoldStatus } from '@lib/state/api/generated/reservations';

import { RoomHoldsModal } from '../room-holds.modal';

const useStyles = makeStyles(theme =>
  createStyles({
    removePromo: {
      color: theme.palette.error.main,
      cursor: 'pointer',
      marginLeft: '0.5rem',
    },
    relative: {
      position: 'relative',
    },
    loadingContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 15,
      backgroundColor: theme.palette.action.disabled,
    },
  })
);

interface FormValues extends LocationFormFields {
  flexRate: boolean;
  dailyHousekeeping: boolean;
  room: {
    roomTypeId: string;
    dailyRates: Array<DailyRate>;
  };
  amenities: Array<OptionalAmenity>;
}

interface EnabledRoomRateFormFeature {
  enabledFeatures?: {
    selectRoomType?: boolean;
    changeDates?: boolean;
    changeRoomQuantity?: boolean;
    allowPromoCode?: boolean;
    addFlexibility?: boolean;
    addAmenities?: boolean;
    addDailyHousekeeping?: boolean;
  };
}

interface Props extends EnabledRoomRateFormFeature {
  className?: string;
  properties: Array<Property>;
  roomTypes: Array<RoomType>;
  roomRates: Array<RoomRate>;
  promo?: string | null;
  policy?: PropertyPolicy;
  usePaper?: boolean | undefined;
  defaultPropertyId?: string | null;
  defaultCheckIn?: Date;
  defaultCheckOut?: Date;
  status: FormStatus;
  variant?: SectionVariant;
  quantity?: number;
  onEditLocation: () => void;
  selectRoomType: (roomType: RoomType) => void;
  setRequiresPayment: React.Dispatch<React.SetStateAction<boolean>>;
  reservation?: Reservation;
  availabilityError?: Error;
  multipleCorpAccts?: boolean;
}

export const RoomRateForm: React.FC<Props> = ({
  className,
  properties,
  roomTypes,
  roomRates,
  promo = '',
  policy,
  usePaper,
  status,
  variant,
  onEditLocation,
  selectRoomType,
  setRequiresPayment,
  enabledFeatures = {
    selectRoomType: true,
    changeDates: true,
    changeRoomQuantity: true,
  },
  reservation,
  availabilityError,
  multipleCorpAccts,
}) => {
  const { removePromo, relative, loadingContainer } = useStyles();
  const styles = useCommonStyle();

  const [openRoomHoldsModal, setOpenRoomHoldsModal] = useState(false);

  const [editPromoCode, setEditPromoCode] = useState(false);
  const [didSubmitPromo, setDidSubmitPromo] = useState<string | undefined>(undefined);
  const corporateBooking = useObservable(roomRateQuery.corporateBooking);
  const changeBillingRefunds = useObservable(roomRateQuery.changeBillingRefunds);
  const propertyLookup = useLookup(properties, x => x.id);
  const rateLookup = useLookup(roomRates, x => x.roomTypeId);
  const roomTypeLookup = useLookup(roomTypes, x => x.id);
  const { register, watch, setValue } = useFormContext<FormValues>();

  useEffect(() => {
    register({ name: 'room.id' });
    register({ name: 'room.roomTypeId' });
    register({ name: 'room.dailyRates' });
  }, []);

  const promoCode = watch('promoCode', promo);
  const dailyHousekeeping = watch('dailyHousekeeping');

  const { propertyId, checkInDate, checkOutDate, flexRate, room, quantity, amenities = [] } = watch(
    {
      nest: true,
    }
  );

  const rate = useMemo(() => rateLookup.get(room?.roomTypeId), [roomRates, room?.roomTypeId]);

  const housekeepingIncluded = useMemo(
    () => !!corporateBooking?.housekeeping || rate?.totalHousekeepingRate === 0,
    [rate, corporateBooking]
  );

  const housekeepingDefaultChecked = useMemo(
    () => !!reservation?.hasHousekeeping || housekeepingIncluded,
    [reservation, housekeepingIncluded]
  );

  const availableRoomTypes = useMemo(() => {
    return roomRates
      .map(m => roomTypeLookup.get(m.roomTypeId))
      .filter(f => !!f)
      .map(m => m!)
      .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0));
  }, [roomTypeLookup, roomRates]);

  useEffect(() => {
    if (!roomRates.length) return;

    const rate = roomRates[0]; // default to standard rate of the first room type

    setValue('room', {
      roomTypeId: rate.roomTypeId,
      dailyRates: rate.dailyRates,
    });

    // Forcing react-hook-form to run validation for the next
    // two setValue calls is needed for the form to re-render

    // Default flex to true when flex rate is 0.
    setValue('flexRate', !!reservation?.changeable || flexRate || rate.totalFlexRate === 0, true);

    if (rate.dailyRates.length > 1) {
      // Default dailyHousekeeping to true when corporateBooking has
      // housekeeping overrides or housekeeping rate is 0.
      setValue('dailyHousekeeping', housekeepingDefaultChecked || !!dailyHousekeeping, true);
    }
  }, [roomRates, corporateBooking, reservation, housekeepingDefaultChecked]);

  const amenityLookup = useLookup(rate?.amenities, x => x.code);

  const includedAmenities = useMemo(() => rate?.amenities.filter(x => x.isIncluded), [rate]);
  const optionalAmenities = useMemo(() => rate?.amenities.filter(x => !x.isIncluded), [rate]);

  const housekeeping = useMemo(
    () => corporateBooking?.housekeeping ?? { touchUpCleanFrequency: dailyHousekeeping ? 1 : null },
    [corporateBooking, dailyHousekeeping]
  );
  const roomsWatch = watch('rooms');

  // determines the amount of late checkouts
  // for an existing reservation, in the corporate model, each reservation will only have one room
  const hasAutomaticLateCheckout = useMemo(() => {
    return !!reservation?.hasAutomaticLateCheckout;
  }, [reservation]);

  // for a new booking, this amount is determined by the checking the sleep schedule of each room
  const lateCheckouts = useMemo(() => {
    if (roomsWatch instanceof Array)
      return roomsWatch.filter(function ({ sleepSchedule }) {
        return sleepSchedule && sleepSchedule === SleepSchedule.DaySleeper;
      }).length;

    return null;
  }, [roomsWatch]);

  const {
    grandAmount,
    grandTaxesAndFees,
    grandTotal,
    grandChangeBillingRefundsTotal,
    grandAmenities,
    ...totals
  } = useMemo(() => {
    // null values for totals prevents requiresPayment toggling which
    // can lead to rapid form swapping and react-hook-form errors
    const totals: {
      amount: number;
      fees: number;
      amenities: number;
      tax: number;
      taxesAndFees: number | null;
      total: number | null;
      totalLateCheckoutFees: number | null;
      grandAmount: number | null;
      grandTaxesAndFees: number | null;
      grandTotal: number | null;
      grandChangeBillingRefundsTotal: number | null;
      grandAmenities: number | null;
      quantity: number;
    } = {
      amount: 0,
      fees: 0,
      amenities: 0,
      tax: 0,
      taxesAndFees: null,
      total: null,
      totalLateCheckoutFees: null,
      grandAmount: null,
      grandTaxesAndFees: null,
      grandTotal: null,
      grandChangeBillingRefundsTotal: null,
      grandAmenities: null,
      quantity: 1,
    };

    if (!rate || !quantity) return totals;

    const useSalesTaxForMealPlan =
      properties.find(p => p.id === propertyId)?.configuration?.useSalesTaxForMealCosts ?? true;
    const { state, county, city } = rate.taxRates;
    const { state: salesState, county: salesCounty, city: salesCity } = rate.salesTaxRates;
    const lastStayDate = rate.dailyRates[rate.dailyRates.length - 1];

    function calcTotalLateCheckoutFees() {
      if (hasAutomaticLateCheckout && rate) return rate.lateCheckOutFee * 1;

      if (corporateBooking?.isAutomaticLateCheckoutEnabled && lateCheckouts && rate) {
        return rate.lateCheckOutFee * lateCheckouts;
      }
      return null;
    }

    totals.totalLateCheckoutFees = calcTotalLateCheckoutFees();

    function calcTax(amount: number, isSalesTaxes: boolean) {
      return !isSalesTaxes
        ? Math.round(amount * (state + county + city)) / 100
        : Math.round(amount * (salesState + salesCounty + salesCity)) / 100;
    }

    let refundTotal: number = 0;

    for (const day of rate.dailyRates) {
      let amount = day.standardRate;

      const includeChangeBillingRefunds: boolean =
        !!changeBillingRefunds &&
        !!changeBillingRefunds.refundedStayDates &&
        !!changeBillingRefunds.refundedStayDates.find(
          x => new Date(x).toLocaleDateString() === day.date.toLocaleDateString()
        );

      if (flexRate) {
        amount += day.flexRate;
      }
      if (dailyHousekeeping && day !== lastStayDate) {
        amount += day.housekeepingRate;
      }

      totals.amount += amount;
      const tax = calcTax(amount, false);
      totals.tax += tax;

      if (includeChangeBillingRefunds) {
        refundTotal += amount;
        refundTotal += tax;
      }
    }

    for (const amenity of amenities) {
      if (!amenity.selected) continue;

      //Need a better way to do this
      const isMealPlan = amenity.code === 'meals-all';

      const amenityFee = amenityLookup.get(amenity.code)?.totalFee ?? 0;

      totals.amenities += amenityFee;
      totals.tax += calcTax(amenityFee, !isMealPlan ? true : useSalesTaxForMealPlan);

      if (
        changeBillingRefunds?.refundedStayDates &&
        changeBillingRefunds.refundedStayDates.length > 0
      ) {
        const amenityRefund =
          (amenityFee / rate.dailyRates.length) * changeBillingRefunds.refundedStayDates.length;
        refundTotal += amenityRefund;
        const amenityRefundTax = calcTax(amenityRefund, true);
        refundTotal += amenityRefundTax;
      }
    }

    const housekeepingFee = rate.housekeepingFee;
    const housekeepingFeeTax = calcTax(housekeepingFee, false);
    totals.fees = housekeepingFee;
    totals.tax += housekeepingFeeTax;
    if (reservation?.status === ReservationStatus.Canceled) {
      refundTotal += housekeepingFee;
      refundTotal += housekeepingFeeTax;
    }

    totals.quantity = quantity;

    totals.taxesAndFees = totals.fees + totals.tax;
    totals.total = totals.fees + totals.amenities + totals.amount + totals.tax;

    totals.grandAmount = totals.amount * totals.quantity;
    totals.grandAmenities = totals.amenities * totals.quantity;
    totals.grandTaxesAndFees = totals.taxesAndFees * totals.quantity;
    totals.grandTotal = totals.total * totals.quantity;

    if (totals.totalLateCheckoutFees) {
      let lateCheckOutTax = calcTax(totals.totalLateCheckoutFees, false);
      totals.grandTotal += totals.totalLateCheckoutFees + lateCheckOutTax;
      totals.grandTaxesAndFees += totals.totalLateCheckoutFees + lateCheckOutTax;
    }

    if (refundTotal > 0) {
      totals.grandChangeBillingRefundsTotal = -1 * refundTotal;
      totals.grandTotal += totals.grandChangeBillingRefundsTotal;
    }

    return totals;
  }, [
    reservation,
    rate,
    flexRate,
    dailyHousekeeping,
    amenities,
    amenityLookup,
    quantity,
    corporateBooking,
    changeBillingRefunds,
    lateCheckouts,
    hasAutomaticLateCheckout,
  ]);

  useEffect(() => {
    setRequiresPayment(grandTotal !== 0);
  }, [grandTotal, setRequiresPayment]);

  const roomType = roomTypeLookup.get(room?.roomTypeId);
  useEffect(() => {
    if (roomType) selectRoomType(roomType);
  }, [roomType]);

  const applyPromoCode = useCallback(() => {
    roomRateService.updateUI({ promoCode: promoCode ?? undefined });
    setDidSubmitPromo(promoCode ?? undefined);
  }, [promoCode]);

  const removePromoCode = useCallback(() => {
    setValue('promoCode', '');

    roomRateService.updateUI({ promoCode: undefined });
    setDidSubmitPromo(undefined);
    setEditPromoCode(false);
  }, [promoCode, setValue]);

  const setRoomType = useCallback(
    (id: string) => {
      setValue('room.roomTypeId', id);
      const rate = rateLookup.get(id);
      setValue('room.dailyRates', rate?.dailyRates);
    },
    [setValue, rateLookup, roomTypeLookup]
  );

  useEffect(() => {
    const isChecked = rate?.roomHoldStatus === RoomHoldStatus.Required;
    setValue('useRoomHolds', isChecked);
  }, [rate?.roomHoldStatus, setValue]);

  const setQuantity = useCallback(
    (quantity: string) => {
      roomRateService.updateUI({ quantity: parseInt(quantity) });
      setValue('quantity', parseInt(quantity));
    },
    [setValue]
  );

  const { errors } = useFormContext();
  const fieldRef = React.createRef<any>();

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0 && fieldRef.current) {
      window.scrollTo({ behavior: 'auto', top: fieldRef.current?.offsetTop });
    }
  }, [errors, fieldRef.current]);

  if (!propertyId || !checkInDate || !checkOutDate || !roomType || !rate || !quantity) return null;

  return (
    <>
      <Analytics
        {...{
          dailyHousekeeping: dailyHousekeeping,
          total: grandTotal != null ? formatCurrency(grandTotal) : undefined,
          flex: flexRate,
          promoCode: rate?.isPromotional === true ? promoCode : undefined,
          isCorporateBooking: !!corporateBooking,
          corporateName: corporateBooking?.name,
        }}
      />
      <Section
        className={clsx(className, relative)}
        title="Customize Your Stay"
        maxWidth="md"
        variant={variant}
        data-testid="customizeStayDisplay"
      >
        <Paper className={clsx({ [styles.paper]: usePaper, [styles.notPaper]: !usePaper })} square>
          {status === FormStatus.Pending && (
            <div className={loadingContainer}>
              <LoadingIndicator loadingText="" fillContainer />
            </div>
          )}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <AddressDisplay
              addressVariant="cityState"
              variant="subtitle1"
              {...propertyLookup.get(propertyId)?.location}
              data-testid="customizeStayAddressDisplay"
            />
            {!!enabledFeatures?.changeDates && (
              <Link
                component="button"
                type="button"
                color="primary"
                onClick={onEditLocation}
                variant="body2"
                name="changeDates"
                data-testid="customizeStayChangeDatesButton"
              >
                <small>Change Dates</small>
              </Link>
            )}
          </Box>
          <ErrorDisplay error={availabilityError} />
          <Grid container spacing={2}>
            <Grid item sm={3} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong># of Rooms</strong>
              </Typography>
              {!enabledFeatures?.changeRoomQuantity ? (
                <Typography className={styles.dense} variant="body2">
                  {quantity}
                </Typography>
              ) : (
                <FormTextField
                  name="quantity"
                  color="primary"
                  value={quantity ?? '1'}
                  onChange={e => {
                    setQuantity(e.target.value);
                  }}
                  select
                  size="small"
                  controlled
                  fullWidth
                  data-testid="customizeStayQuantity"
                >
                  {[...Array(25)].map((x, i) => (
                    <MenuItem key={i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </FormTextField>
              )}
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Check-In</strong>
              </Typography>
              <DateDisplay
                className={styles.dense}
                variant="body2"
                date={checkInDate}
                dateFormat="MM/dd/yyyy"
                data-testid="customizeStayCheckInDateDisplay"
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Check-Out</strong>
              </Typography>
              <DateDisplay
                className={styles.dense}
                variant="body2"
                date={checkOutDate}
                dateFormat="MM/dd/yyyy"
                data-testid="customizeStayCheckOutDateDisplay"
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography className={styles.dense} variant="overline">
                <strong>Room Type</strong>
              </Typography>
              <Typography
                className={styles.dense}
                variant="body2"
                data-testid="customizeStayRoomTypeDisplay"
              >
                {roomType.name}
              </Typography>
            </Grid>

            {availableRoomTypes.length > 1 && !!enabledFeatures?.selectRoomType && (
              <Grid item xs={12}>
                <Typography className={styles.dense} variant="overline">
                  <strong>Select Room Type</strong>
                </Typography>
                <Select
                  label="Type"
                  value={roomType?.id}
                  fullWidth
                  onChange={e => setRoomType(e.target.value as string)}
                  data-testid="customizeStayRoomTypeSelect"
                >
                  {availableRoomTypes.map(x => (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
                <small>{roomType.description}</small>
              </Grid>
            )}

            <Grid item xs={12}>
              {corporateBooking && rate.roomHoldStatus !== RoomHoldStatus.Unavailable && (
                <Box display="flex" alignItems="center">
                  <FormCheckbox
                    name="useRoomHolds"
                    label={<Typography variant="body2">Use Corporate Room Holds</Typography>}
                    disabled={rate.roomHoldStatus === RoomHoldStatus.Required}
                  />

                  <Link onClick={() => setOpenRoomHoldsModal(true)}>Show Availability</Link>
                  <RoomHoldsModal
                    propertyId={propertyId}
                    open={openRoomHoldsModal}
                    roomTypeId={room.roomTypeId}
                    corporateAccount={corporateBooking}
                    checkIn={checkInDate}
                    checkOut={checkOutDate}
                    onClose={() => setOpenRoomHoldsModal(false)}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          <Box mt={1} mb={0.5}>
            <Divider />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={styles.dense} variant="subtitle2">
              Room Charges
            </Typography>

            <Box display="flex" alignItems="center" flexDirection="column">
              <CurrencyDisplay
                value={rate.averageStandardRate}
                className={styles.dense}
                variant="body2"
              >
                /night
              </CurrencyDisplay>
            </Box>
          </Box>

          {rate.isCorporate && corporateBooking && (
            <Box display="flex" dir="row" alignItems="center">
              <Typography
                className={styles.dense}
                variant="body2"
                data-testid="roomRateCorporateBookingDisplay"
                style={{
                  display: 'flex',
                }}
              >
                Using corporate booking rates for {corporateBooking.name}
              </Typography>
              {multipleCorpAccts && (
                <Link
                  component="button"
                  color="primary"
                  onClick={onEditLocation}
                  variant="body2"
                  name="changeDates"
                  data-testid="customizeStayChangeDatesButton"
                  style={{
                    margin: '0 0.5rem',
                  }}
                >
                  <small>(change account)</small>
                </Link>
              )}
            </Box>
          )}

          <Box>
            {rate?.isPromotional && (
              <Typography className={styles.dense} variant="caption">
                <small>Promotional rate applied.</small>
                <small className={removePromo} onClick={removePromoCode}>
                  (Remove)
                </small>
              </Typography>
            )}

            {!rate?.isPromotional &&
              (!enabledFeatures?.allowPromoCode || enabledFeatures.allowPromoCode === true) && (
                <>
                  {editPromoCode ? (
                    <>
                      <Box display="flex" alignItems="flex-end">
                        <FormTextField
                          name="promoCode"
                          label="Promo Code"
                          defaultValue=""
                          size="small"
                          data-testid="customizeStayPromoCodeInput"
                        />
                        <Box display="inline-flex" alignItems="center" ml={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={applyPromoCode}
                            name="applyPromoCode"
                            disabled={!promoCode || status === FormStatus.Pending}
                            data-testid="customizeStayPromoCodeApplyButton"
                          >
                            Apply
                          </Button>
                          <Box ml={2}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="small"
                              onClick={() => setEditPromoCode(false)}
                              data-testid="customizePromoCodeCancelButton"
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Box>
                      </Box>

                      {didSubmitPromo &&
                        (status === FormStatus.Initial || status === FormStatus.Success) && (
                          <Typography color="error">
                            <small>Code is invalid or cannot be applied to this reservation.</small>
                          </Typography>
                        )}
                    </>
                  ) : (
                    <Link
                      component="button"
                      type="button"
                      color="primary"
                      onClick={() => setEditPromoCode(true)}
                      variant="body2"
                      data-testid="customizeStayPromoCodeButton"
                    >
                      <small>Have a promotion or corporate booking code?</small>
                    </Link>
                  )}
                </>
              )}
          </Box>

          <Box mt={0.5} mb={1}>
            <Divider />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={styles.dense} variant="subtitle2">
              Booking Flexibility
            </Typography>
            {flexRate && (
              <>
                {rate.totalFlexRate > 0 ? (
                  <CurrencyDisplay
                    className={styles.dense}
                    variant="body2"
                    addon
                    value={rate.averageFlexRate}
                  >
                    /night
                  </CurrencyDisplay>
                ) : (
                  <Typography className={styles.dense} variant="body2">
                    Included
                  </Typography>
                )}
              </>
            )}
          </Box>
          <Typography className={styles.dense} variant="caption" display="block">
            {flexRate ? (
              <small>
                Reservation refundable up to {Math.round(rate.cancellationWindow / 60)} hrs before
                check-in.
              </small>
            ) : (
              <small>Reservation is currently NONREFUNDABLE.</small>
            )}
          </Typography>
          <FormCheckbox
            className={clsx({ [styles.hidden]: rate.totalFlexRate === 0 })}
            name="flexRate"
            label={<Typography variant="body2">Add booking flexibility</Typography>}
            fullWidth
            defaultChecked={!!reservation?.changeable || rate.totalFlexRate === 0}
            data-testid="customizeStayFlexRateCheck"
            disabled={enabledFeatures?.addFlexibility === false}
          />
          <Box my={1}>
            <Divider />
          </Box>

          {room?.dailyRates?.length > 1 && (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={styles.dense} variant="subtitle2">
                  Housekeeping
                </Typography>
                {dailyHousekeeping && (
                  <>
                    {rate.totalHousekeepingRate > 0 ? (
                      <CurrencyDisplay
                        className={styles.dense}
                        variant="body2"
                        addon
                        value={rate.averageHousekeepingRate}
                      >
                        /night
                      </CurrencyDisplay>
                    ) : (
                      <Typography className={styles.dense} variant="body2">
                        Included
                      </Typography>
                    )}
                  </>
                )}
              </Box>

              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={styles.dense} variant="caption" display="block">
                  <small>
                    <HousekeepingPolicyDisplay {...housekeeping} />
                  </small>
                </Typography>
                {dailyHousekeeping && (
                  <Typography className={styles.dense} variant="caption" display="block">
                    <small>For {rate.dailyRates.length - 1} nights.</small>
                  </Typography>
                )}
              </Box>
              <FormCheckbox
                className={clsx({
                  [styles.hidden]: housekeepingIncluded,
                })}
                name="dailyHousekeeping"
                label={<Typography variant="body2">Add daily housekeeping</Typography>}
                fullWidth
                defaultChecked={housekeepingDefaultChecked}
                data-testid="customizeStayHousekeepingCheck"
                disabled={enabledFeatures?.addDailyHousekeeping === false}
              />
              <Box my={1}>
                <Divider />
              </Box>
            </>
          )}

          {rate?.amenities.length > 0 && (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography className={styles.dense} variant="subtitle2">
                  Amenities & Add-Ons
                </Typography>
                {amenities.some(x => x.selected) && (
                  <CurrencyDisplay
                    className={styles.dense}
                    variant="body2"
                    addon
                    value={amenities
                      .filter(x => x.selected)
                      .reduce((sum, x) => sum + (amenityLookup.get(x.code)?.averageFee ?? 0), 0)}
                  >
                    /night
                  </CurrencyDisplay>
                )}
              </Box>
              <Typography className={styles.dense} variant="caption" display="block">
                {!!includedAmenities?.length ? (
                  <small>Your stay includes: {includedAmenities.map(x => x.name).join(', ')}</small>
                ) : (
                  <small>Select from the options below.</small>
                )}
              </Typography>
              {optionalAmenities?.map((amenity, i) => (
                <React.Fragment key={amenity.code}>
                  <FormTextField
                    type="hidden"
                    name={`amenities.[${i}].code`}
                    defaultValue={amenity.code}
                  />
                  <FormCheckbox
                    name={`amenities[${i}].selected`}
                    label={<Typography variant="body2">{amenity.name}</Typography>}
                    fullWidth
                    defaultChecked={!!amenity.selected}
                    disabled={enabledFeatures?.addAmenities === false}
                  />
                </React.Fragment>
              ))}
              <Box mt={1} mb={0.5}>
                <Divider />
              </Box>
            </>
          )}
          {totals.quantity > 1 && (
            <>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">Per room charges</Typography>
                <CurrencyDisplay value={totals.amount} variant="body2" />
              </Box>
              {totals.amenities > 0 && (
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="body2">Per room amenity & add-on charges</Typography>
                  <CurrencyDisplay value={totals.amenities} variant="body2" />
                </Box>
              )}
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">Per room taxes and fees</Typography>
                <CurrencyDisplay value={totals.taxesAndFees} variant="body2" />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="body2">Requested # of rooms</Typography>
                <Typography variant="body2">{totals.quantity}</Typography>
              </Box>

              <Box my={0.5}>
                <Divider />
              </Box>
            </>
          )}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Total room charges</Typography>
            <CurrencyDisplay value={grandAmount} variant="body2" />
          </Box>
          {!!grandAmenities && grandAmenities > 0 && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">Total amenity &amp; add-on charges</Typography>
              <CurrencyDisplay value={grandAmenities} variant="body2" />
            </Box>
          )}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Total taxes and fees</Typography>
            <CurrencyDisplay value={grandTaxesAndFees} variant="body2" />
          </Box>
          {!!grandChangeBillingRefundsTotal && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="body2">Total refunds</Typography>
              <CurrencyDisplay value={grandChangeBillingRefundsTotal} variant="body2" />
            </Box>
          )}

          <Box my={0.5}>
            <Divider />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle1">Total for stay</Typography>
            <CurrencyDisplay
              value={grandTotal}
              variant="subtitle1"
              data-testid="customizeStayTotalDisplay"
            />
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <div ref={fieldRef}>
              <FormCheckbox
                name="acceptPolicy"
                label={
                  <Typography variant="body2">
                    I agree to the <RulesAndRestrictionsModal policy={policy} />
                  </Typography>
                }
                validationOptions={{
                  validate: {
                    accepted: value => value || 'Rules and restrictions must be accepted.',
                  },
                }}
                helperText={errors => errors['acceptPolicy']?.message}
                data-testid="customizeStayAcceptPolicyCheck"
              />
            </div>
          </Box>
        </Paper>
      </Section>
    </>
  );
};
