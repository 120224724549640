import {
  ErrorDisplay,
  Feedback,
  FormEmailField,
  FormHub,
  Loading,
  StyledDialog,
  SubmitButton,
  useCommonStyle,
  useFormEvents,
  useGuestStays,
  useObservable,
} from '@lib/common';
import {
  FormStatus,
  QRCodeStatus,
  Room,
  RoomForms,
  roomsQuery,
  roomsService,
  sessionQuery,
} from '@lib/state';
import { Button, DialogActions, DialogContent, Paper, styled, Tab, Tabs } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQRCodes } from 'app/qr-codes';
import { RoomAccessControlTab, RoomDetailTab, RoomQRCodes } from './room-detail-tabs';
import { lightFormat, startOfDay } from 'date-fns';
import { RoomLogsTab } from './room-detail-tabs/room-logs-tab';

interface Props {
  room: Room;
  propertyId: string;
  open: boolean;
  onClose: () => void;
}
const TabContainer = styled(TabPanel)(() => ({
  minHeight: 280,
}));

interface RequestDatabaseFields {
  email: string;
}

export function RoomDetailsModal({ room, propertyId, open, onClose }: Props) {
  const [
    { status: unlockRoomDoorStatus, error: unlockRoomDoorError },
    unlockRoomDoorReset,
  ] = useFormEvents(RoomForms.UnlockDoor);
  const { paper } = useCommonStyle();

  const [
    { status: requestDatabaseStatus, error: requestDatabaseError },
    requestDatabaseReset,
  ] = useFormEvents(RoomForms.RequestDatabase);

  const [showEmailInput, setShowEmailInput] = useState(false);

  const user = useObservable(sessionQuery.currentUser);
  const isAdmin = user?.role.includes('Administrator');

  useEffect(() => {
    roomsService.getRoomById(propertyId, room.id, true);
  }, [room, propertyId]);

  const updatedRoom = useObservable(roomsQuery.activeRoom);

  useEffect(() => {
    if (updatedRoom) roomsService.getRoomDoorControllerStatus(updatedRoom);
    // eslint-disable-next-line
  }, [updatedRoom?.id]);

  const onUnlockClick = useCallback(() => {
    if (isAdmin) roomsService.unlockRoomDoor(room.propertyId, room.roomNumber);
  }, [room, isAdmin]);

  const onRequestDatabaseClick = useCallback(
    ({ email }: RequestDatabaseFields) => {
      if (isAdmin) roomsService.requestDatabase(room.propertyId, room.roomNumber, email);
      setShowEmailInput(false);
    },
    [room, isAdmin]
  );

  const { stays, loading } = useGuestStays(
    propertyId,
    lightFormat(startOfDay(new Date(Date.now())), 'yyyy-MM-dd')
  );

  const filters = useMemo(() => {
    return { roomNumber: room.roomNumber, propertyId: propertyId, status: QRCodeStatus.Active };
  }, [propertyId, room]);

  const { qrCodes, loading: qrCodesLoading } = useQRCodes(filters);

  const roomStay = useMemo(() => stays.find(s => s.roomNumber === room.roomNumber), [stays, room]);

  const [tab, setTab] = useState('details');

  const onModalClose = useCallback(() => {
    unlockRoomDoorReset();
    requestDatabaseReset();
    onClose();
  }, [unlockRoomDoorReset, requestDatabaseReset, onClose]);

  return (
    <StyledDialog
      title={`Room: ${room.roomNumber}`}
      onClose={onModalClose}
      open={open}
      maxWidth="lg"
      scroll="paper"
    >
      <TabContext value={tab}>
        {room && (
          <>
            <Tabs
              value={tab}
              onChange={(_, x) => setTab(x)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Room Details" value="details" />
              <Tab label="QR Codes" value="qrcodes" />
              {isAdmin && updatedRoom?.doorControllerStatus?.isProvisioned && (
                <Tab label="Device" value="device" />
              )}
              <Tab label="Logs" value="logs" />
            </Tabs>
            <DialogContent>
              <Feedback
                show={requestDatabaseStatus === FormStatus.Success}
                severity="success"
                onHide={requestDatabaseReset}
              >
                Requested database has been to the entered email.
              </Feedback>
              <ErrorDisplay error={unlockRoomDoorError || requestDatabaseError} />
              {loading || qrCodesLoading ? (
                <Loading />
              ) : (
                <>
                  <TabContainer value="details">
                    <Paper elevation={4} className={paper}>
                      <RoomDetailTab room={updatedRoom ?? room} occupied={!!roomStay} />
                    </Paper>
                  </TabContainer>
                  <TabContainer value="qrcodes">
                    <Paper elevation={4} className={paper}>
                      <RoomQRCodes
                        roomNumber={room.roomNumber}
                        qrCodes={qrCodes}
                        propertyId={propertyId}
                      />
                    </Paper>
                  </TabContainer>
                  <TabContainer value="device">
                    <RoomAccessControlTab room={updatedRoom} />
                  </TabContainer>
                  <TabContainer value="logs">
                    <RoomLogsTab roomId={room.id} />
                  </TabContainer>
                </>
              )}
            </DialogContent>
            {isAdmin && updatedRoom?.doorControllerStatus?.isProvisioned && false && (
              <DialogActions>
                {!showEmailInput && (
                  <>
                    <SubmitButton
                      onClick={onUnlockClick}
                      pending={unlockRoomDoorStatus === FormStatus.Pending}
                    >
                      Unlock Door
                    </SubmitButton>
                    <Button
                      type="button"
                      onClick={() => setShowEmailInput(true)}
                      variant="contained"
                      color="default"
                    >
                      Request Database
                    </Button>
                  </>
                )}
                {showEmailInput && (
                  <FormHub onSubmit={onRequestDatabaseClick}>
                    <FormEmailField name="email" placeholder="Email" required />
                    <SubmitButton
                      pending={requestDatabaseStatus === FormStatus.Pending}
                      variant="contained"
                      color="primary"
                    >
                      Send
                    </SubmitButton>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => setShowEmailInput(false)}
                    >
                      Cancel
                    </Button>
                  </FormHub>
                )}
              </DialogActions>
            )}
          </>
        )}
      </TabContext>
    </StyledDialog>
  );
}
