import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Container } from '@material-ui/core';

import { propertyQuery } from '@lib/state';
import {
  useObservable,
  FormHub,
  Section,
  useFormEvents,
  usePageTitle,
  usePropertyRoomTypes,
  usePropertyAmenities,
} from '@lib/common';
import {
  roomRatePlanService,
  roomRatePlanQuery,
  RoomRatePlanForms,
  employeeService,
  employeeQuery,
  rateRestrictionService,
  rateRestrictionQuery,
  RateRestriction,
  corporateAccountQuery,
  corporateAccountService,
  AdditionalProperty,
} from 'app/state';
import {
  prepareFormValues,
  RatePlanForm,
  RatePlanFormFields,
  RatePlanHistory,
  RateRestrictionsSection,
} from '../components';
import { AuditLogs, BreadcrumbBackButton } from 'app/shared';

export const EditRoomRatePlanPage: React.FC<RouteComponentProps<{
  ratePlanId: string;
}>> = ({ match, location, history }) => {
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const corporateAccountId = params.get('corporateAccountId') ?? undefined;

  const backUrl = useMemo(() => {
    if (corporateAccountId) return `/corporate-accounts/${corporateAccountId}`;
    return '/rates';
  }, [corporateAccountId]);

  const ratePlanId = match.params.ratePlanId;

  const [selectedRates, setSelectedRates] = useState<AdditionalProperty[]>([]);

  usePageTitle('Edit Room Rate');

  const propertyId = useObservable(propertyQuery.activeProperty, 'async')?.id;
  const roomTypes = usePropertyRoomTypes(propertyId);
  const employees = useObservable(employeeQuery.employees);
  const amenities = usePropertyAmenities(propertyId);

  const roomRatePlan = useObservable(roomRatePlanQuery.activeRatePlan, 'async');
  const planHistory = useObservable(roomRatePlanQuery.activeRatePlanHistory);
  const rateRestrictions = useObservable(rateRestrictionQuery.rateRestrictions);
  const corporateAccount = useObservable(corporateAccountQuery.activeAccount);

  const onDeleteRateRestriction = useCallback((rateRestriction: RateRestriction) => {
    rateRestrictionService.deleteRateRestriction(rateRestriction);
  }, []);

  useEffect(() => {
    if (propertyId) {
      employeeService.getEmployees(propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (roomRatePlan) {
      rateRestrictionService.getRateRestrictions(
        roomRatePlan.propertyId,
        roomRatePlan.roomTypeId,
        roomRatePlan.id
      );
    }
  }, [roomRatePlan]);

  useEffect(() => {
    if (roomRatePlan?.affiliation?.corporateAccountId)
      corporateAccountService.getCorporateAccountById(
        roomRatePlan?.affiliation?.corporateAccountId
      );
    return () => corporateAccountService.selectCorporateAccount();
    // eslint-disable-next-line
  }, [roomRatePlan?.affiliation?.corporateAccountId]);

  useEffect(() => {
    roomRatePlanService.getRatePlanById(ratePlanId);
    roomRatePlanService.getRatePlanHistory(ratePlanId);

    return () => roomRatePlanService.selectRatePlan();
  }, [ratePlanId]);

  const defaultValues = useMemo(() => prepareFormValues(amenities, roomRatePlan), [
    amenities,
    roomRatePlan,
  ]);

  const [{ status, error }, reset] = useFormEvents(RoomRatePlanForms.Edit);

  const sectionTitle = useMemo(
    () =>
      corporateAccount
        ? `Edit Room Rate Plan for ${corporateAccount.name} (Corporate Account)`
        : 'Edit Room Rate Plan',
    [corporateAccount]
  );

  if (roomRatePlan == null || !defaultValues) return null;

  if (roomRatePlan.id !== ratePlanId)
    return <Redirect to={`/rates/${roomRatePlan.id}`} push={false} />;

  const onSubmit = (values: RatePlanFormFields) => {
    roomRatePlanService.updateRatePlan(
      {
        ...roomRatePlan,
        ...values,
        amenities: values.amenities.filter(a => a.available),
      },
      selectedRates
    );
  };

  return (
    <Container maxWidth="lg">
      <BreadcrumbBackButton destination={backUrl} />
      <Section title={sectionTitle} textAlign="left" disableGutters>
        <FormHub onSubmit={onSubmit} formOptions={{ defaultValues }}>
          <RatePlanForm
            plan={roomRatePlan}
            corporateAccount={corporateAccount}
            roomTypes={roomTypes}
            status={status}
            error={error}
            onDisable={() => {
              roomRatePlanService.disableRatePlan(roomRatePlan);
              history.push('/rates');
            }}
            onEnable={() => {
              roomRatePlanService.enableRatePlan(roomRatePlan);
              history.push('/rates');
            }}
            resetFormUI={reset}
            setSelectedRates={setSelectedRates}
            selectedRates={selectedRates}
          />
        </FormHub>
      </Section>
      <Section title="History" disableGutters>
        {amenities && (
          <RatePlanHistory
            history={planHistory}
            employees={employees}
            propertyAmenities={amenities}
            roomTypes={roomTypes}
          />
        )}
      </Section>
      <RateRestrictionsSection
        rateRestrictions={rateRestrictions}
        onDeleteRateRestriction={onDeleteRateRestriction}
      />
      {roomRatePlan && (
        <Section title="Audit Logs" disableGutters>
          <AuditLogs scope="rate-plan" id={roomRatePlan.rateKey} />
        </Section>
      )}
    </Container>
  );
};
